import { debounce } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate if using
import AgentsList from "../../components/AgentsList";
import Notification from "../../components/notification";
import API from "../../utils/api";
function AgentsPage() {
  const navigate = useNavigate(); // Use the useNavigate hook for navigation
  const [agents, setAgents] = useState([]);
  const [STATUSES, setStatuses] = useState([]);
  const [notification, setNotification] = useState({
    title: "",
    message: "",
    color: "",
    timeToDisappear: 3000,
  });

  const handleResetNotification = () => {
    setNotification({
      title: "",
      message: "",
      color: "",
      timeToDisappear: 3000,
    });
  };

  // Define the debounced function inside useCallback
  const debouncedPostAgentChange = useCallback(
    debounce((agent_id, agent, resolve, reject) => {
      API.put(`/team/agents/${agent_id}/`, agent, {
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          console.log("Response: ", response);
          resolve(response);
        })
        .catch((error) => {
          console.log("Error: ", error);
          reject(error);
        });
    }, 2000),
    [],
  ); // Dependencies are set as an empty array if nothing affects the debounced function

  const debouncedFetchAgents = useCallback(
    debounce(() => {
      API.get("/team/agents/")
        .then((response) => {
          setAgents(response.data);
        })
        .catch((error) => {
          if (error.response.status === 401) {
            navigate("/login");
          }
          console.log("Error: ", error);
        });
    }, 2000),
    [setAgents],
  );

  const debouncedFetchStatuses = useCallback(
    debounce(() => {
      API.get("/team/agents/statuses/")
        .then((response) => {
          setStatuses(response.data);
        })
        .catch((error) => {
          if (error.response.status === 401) {
            navigate("/login");
          }
          console.log("Error: ", error);
        });
    }, 2000),
    [setStatuses],
  );

  const onChangeAgent = (agent_id, field, value) => {
    let agent = agents.find((agent) => agent.id === agent_id);
    if (agent && field in agent) {
      agent[field] = value;
      setAgents(
        agents.map((a) => (a.id === agent_id ? { ...a, [field]: value } : a)),
      );
      debouncedPostAgentChange(
        agent_id,
        agent,
        (response) => {
          setNotification({
            title: "Success",
            message: "Agent updated successfully!",
            color: "green",
            timeToDisappear: 3000,
          });
        },
        (error) => {
          const data = error.response.data;
          const message = data[field][field];
          const original = data[field]["original"];
          const old_value = original[field];

          setAgents(
            agents.map((a) =>
              a.id === original.id ? { ...a, [field]: old_value } : a,
            ),
          );

          setNotification({
            title: "Error",
            message: message,
            color: "red",
            timeToDisappear: 5000,
          });
        },
      );
    }
  };

  useEffect(() => {
    debouncedFetchAgents();
    debouncedFetchStatuses();
  }, [debouncedFetchAgents, debouncedFetchStatuses]);

  return (
    <div>
      <AgentsList
        agents={agents}
        STATUSES={STATUSES}
        changeAgent={onChangeAgent}
      />
      {notification.message && (
        <Notification
          title={notification.title}
          message={notification.message}
          color={notification.color}
          timeToDisappear={notification.timeToDisappear}
          onDisappear={handleResetNotification}
        />
      )}
    </div>
  );
}

export default AgentsPage;
