import { setUser } from "../redux/userSlice";

/**
 * Initialize user data from localStorage and set it in the Redux store.
 * @param {function} dispatch - The Redux store dispatch function.
 */
const initializeUserData = (dispatch) => {
  const token = localStorage.getItem("token");
  const userName = localStorage.getItem("userName");
  const userRoles = localStorage.getItem("userRoles");
  if (token && userName && userRoles) {
    dispatch(
      setUser({
        userName,
        userRoles, // Ensure userRoles is an array or object
      }),
    );
  }
};

export default initializeUserData;
