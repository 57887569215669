import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Notification from "../components/notification";
import backgroundImage from "../images/login_background.png";
import API from "../utils/api";

function RegisterPage() {
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const navigate = useNavigate();
  const [notification, setNotification] = useState({
    title: "",
    message: "",
    color: "",
    timeToDisappear: 3000,
  });

  const handleResetNotification = () => {
    setNotification({
      title: "",
      message: "",
      color: "",
      timeToDisappear: 3000,
    });
  };

  const handleRegister = async (event) => {
    event.preventDefault();
    try {
      const response = await API.post("/register/", {
        name,
        email,
        password,
      });
      console.log(response.data);
      // Handle registration success (optional: log in directly or navigate to login page)
      setNotification({
        title: "Success",
        message: "Registration successful. You can now log in.",
        color: "green",
        timeToDisappear: 5000,
      });
      navigate("/login/"); // Redirect to login page or home page
    } catch (error) {
      console.error("Registration error", error.response);
      for (const key in error.response.data) {
        setNotification({
          title: `Error: ${key}`,
          message: `${error.response.data[key]}`,
          color: "red",
          timeToDisappear: 5000,
        });
      }
    }
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const registerStyle = {
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "calc(10px + 2vmin)",
    height: "100%",
    width: "100vw",
    backgroundImage: `url(${backgroundImage})`,
  };

  return (
    <div style={registerStyle}>
      <div>Register</div>
      <form
        onSubmit={handleRegister}
        className="flex flex-col items-center space-y-2"
      >
        <div>
          <input
            value={email}
            onChange={handleEmailChange}
            type="email"
            className="input email"
            placeholder="Email"
          />
        </div>
        <div>
          <input
            value={name}
            onChange={handleNameChange}
            type="text"
            className="input name"
            placeholder="Full Name"
          />
        </div>
        <div>
          <input
            value={password}
            onChange={handlePasswordChange}
            type="password"
            className="input password"
            placeholder="Password"
          />
        </div>
        <div className="bg-[#009cff] text-white border-none rounded-md px-4 py-2 m-2 cursor-pointer text-base hover:bg-[#61dafb] hover:text-black">
          <button type="submit">Register</button>
        </div>
      </form>
      {notification.message && (
        <Notification
          title={notification.title}
          message={notification.message}
          color={notification.color}
          timeToDisappear={notification.timeToDisappear}
          onDisappear={handleResetNotification}
        />
      )}
    </div>
  );
}

export default RegisterPage;
