import { debounce } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate if using
import ConfirmationModal from "../components/Confirmation";
import Notification from "../components/notification";
import API from "../utils/api";
import copyToClipboard from "../utils/copyToClipboard";

function FilesPage() {
  const navigate = useNavigate(); // Use the useNavigate hook for navigation
  const userRoles = localStorage.getItem("userRoles");
  const [files, setFiles] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [pagination, setPagination] = useState({ next: null, previous: null });
  const [notification, setNotification] = useState({
    title: "",
    message: "",
    color: "",
    timeToDisappear: 3000,
  });
  const [file, setFile] = useState({
    file_id: "",
    file: null,
    description: "",
    tags: [],
  });

  const [existingTags, setExistingTags] = useState([]);
  const [filteredTags, setFilteredTags] = useState([]);

  const [tagInput, setTagInput] = useState("");

  const [fileToDelete, setFileToDelete] = useState(null); // Track file for deletion
  const [isModalOpen, setIsModalOpen] = useState(false); // Manage modal state

  const openDeleteModal = (file) => {
    setFileToDelete(file);
    setIsModalOpen(true); // Open modal
  };

  const closeDeleteModal = () => {
    setIsModalOpen(false);
    setFileToDelete(null); // Clear file to delete
  };

  const handleResetNotification = () => {
    setNotification({
      title: "",
      message: "",
      color: "",
      timeToDisappear: 3000,
    });
  };

  const handleFilterTags = (query) => {
    if (query.trim() === "") {
      setFilteredTags([]);
      return;
    }

    const filtered = existingTags.filter(
      (tag) =>
        tag.toLowerCase().includes(query.toLowerCase()) &&
        !file.tags.includes(tag),
    );
    setFilteredTags(filtered);
  };

  const handleSelectTag = (tag) => {
    setFile((prev) => ({
      ...prev,
      tags: [...prev.tags, tag],
    }));
    setTagInput(""); // Clear input
    setFilteredTags([]); // Close dropdown
  };

  const handleAddTag = (e) => {
    if (e.key === "Enter" && tagInput.trim() !== "") {
      setFile((prev) => ({
        ...prev,
        tags: [...prev.tags, tagInput.trim()],
      }));
      setTagInput(""); // Clear the input after adding the tag
    }
  };

  const handleRemoveTag = (index) => {
    setFile((prev) => ({
      ...prev,
      tags: prev.tags.filter((_, i) => i !== index),
    }));
  };

  const handleTagBackspace = (e) => {
    if (e.key === "Backspace" && tagInput === "" && file.tags.length > 0) {
      setTagInput(file.tags[file.tags.length - 1]); // Bring back the last tag to the input
      setFile((prev) => ({
        ...prev,
        tags: prev.tags.slice(0, -1), // Remove the last tag
      }));
    }
  };

  const handleAddTagOnBlur = (e) => {
    if (tagInput.trim() !== "") {
      setFile((prev) => ({
        ...prev,
        tags: [...prev.tags, tagInput.trim()],
      }));
      setTagInput(""); // Clear the input after adding the tag
      setFilteredTags([]); // Close dropdown
    }
  };

  const handleCopyURL = async (file) => {
    const url = `${window.location.origin}${file.short_url}?name=${encodeURIComponent(file.file_name)}`;
    console.log("Copying url:", url);
    const success = await copyToClipboard(url);
    if (success) {
      setNotification({
        title: "Success",
        message: "URL copied to clipboard!",
        color: "green",
        timeToDisappear: 3000,
      });
    } else {
      setNotification({
        title: "Failed",
        message: "URL copy failed!",
        color: "red",
        timeToDisappear: 3000,
      });
    }
  };

  const handleViewFile = (file) => {
    const url = `${window.location.origin}${file.short_url}?name=${encodeURIComponent(file.file_name)}`;
    console.log("Opening file:", url);
    window.open(url, "_blank");
  };

  const handleDownloadFile = (file) => {
    const url = `${window.location.origin}${file.short_url}?name=${encodeURIComponent(file.file_name)}&download=true`;
    console.log("Downloading file:", url);
    // Create a temporary anchor element
    const a = document.createElement("a");
    a.href = url;
    a.download = file.file_name;

    // Append to the document, trigger click, and remove it
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  // Debounced fetch files with pagination
  const debouncedFetchFiles = useCallback(
    debounce((url) => {
      API.get(url || "/files/")
        .then((response) => {
          setFiles(response.data.results);
          setPagination({
            next: response.data.next,
            previous: response.data.previous,
          });
        })
        .catch((error) => {
          console.log("Error: ", error);
          if (error.response.status === 401) {
            navigate("/login?next=/files");
          }
          console.log("Error: ", error);
        });
    }, 2000),
    [setFiles],
  );

  // Debounced file upload
  const debouncedUploadFile = useCallback(
    debounce((formData, resolve, reject) => {
      API.post("/files/upload/", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    }, 2000),
    [],
  );

  const debouncedSearch = useCallback(
    debounce((query) => {
      API.get(`/files/?search=${query}`)
        .then((response) => {
          setFiles(response.data.results);
          setPagination({
            next: response.data.next,
            previous: response.data.previous,
          });
        })
        .catch((error) => {
          console.error("Error fetching search results:", error);
        });
    }, 500), // 500ms debounce interval
    [],
  );

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchQuery(value);

    // Trigger debounced search
    if (value.trim() === "") {
      // If the query is cleared, fetch all files
      API.get("/files/")
        .then((response) => {
          setFiles(response.data.results);
          setPagination({
            next: response.data.next,
            previous: response.data.previous,
          });
        })
        .catch((error) => {
          console.error("Error fetching files:", error);
        });
    } else {
      debouncedSearch(value); // Perform the debounced search
    }
  };

  const handleSubmit = () => {
    const formData = new FormData();
    formData.append("file_id", file.file_id);
    formData.append("file", file.file);
    formData.append("description", file.description);
    formData.append("tags", file.tags);

    debouncedUploadFile(
      formData,
      (response) => {
        setNotification({
          title: "Success",
          message: "File uploaded successfully!",
          color: "green",
          timeToDisappear: 3000,
        });
        if (pagination.previous === null) {
          setFiles([response.data, ...files]); // Add new file to the top
        } else {
          debouncedFetchFiles(); // Refresh files
        }
        debouncedFetchTags(); // Refresh tags
        // Reset file state
        setFile({
          file_id: "",
          file: null,
          description: "",
          tags: [],
        });
      },
      (error) => {
        const msg = error.response.data.error || "File upload failed.";
        setNotification({
          title: "Error",
          message: msg,
          color: "red",
          timeToDisappear: 5000,
        });
      },
    );
  };

  const handleDeleteFile = () => {
    if (fileToDelete) {
      API.delete(`/files/${fileToDelete.file_id}/`)
        .then(() => {
          setNotification({
            title: "Success",
            message: "File deleted successfully!",
            color: "green",
            timeToDisappear: 3000,
          });
          setFiles(
            files.filter((file) => file.file_id !== fileToDelete.file_id),
          );
          closeDeleteModal(); // Close modal
        })
        .catch((error) => {
          const msg = error.response.data.error || "Failed to delete file.";
          setNotification({
            title: "Error",
            message: msg,
            color: "red",
            timeToDisappear: 5000,
          });
        });
    }
  };

  const handlePagination = (url) => {
    if (url) {
      debouncedFetchFiles(url);
    }
  };

  const debouncedFetchTags = useCallback(
    debounce(() => {
      API.get("/files/tags")
        .then((response) => {
          setExistingTags(response.data.results);
        })
        .catch((error) => {
          console.log("Error: ", error);
          if (error.response.status === 401) {
            navigate("/login?next=/files");
          }
        });
    }, 2000),
    [setExistingTags],
  );

  useEffect(() => {
    debouncedFetchFiles();
    debouncedFetchTags();
    const userName = localStorage.getItem("userName");
    if (!userName) {
      navigate("/login?next=/files");
    } else if (!userRoles || !userRoles.includes("admin")) {
      navigate("/dashboard");
    }
    // fetchTags();
  }, [debouncedFetchFiles, debouncedFetchTags, navigate, userRoles]);

  if (!userRoles || !userRoles.includes("admin")) {
    return navigate("/dashboard"); // Redirect to dashboard if user is not an admin
  } else {
    return (
      <div className="flex flex-col items-center justify-items-center w-[100vw]">
        <h1>File Management</h1>

        {/* File Upload */}
        <div>
          <div className="flex flex-row items-start justify-between mt-10 space-x-5 w-[60vw]">
            <div className="relative w-[10vw]">
              <label
                htmlFor="file-upload"
                className="flex items-center justify-center bg-blue-500 hover:bg-blue-600 text-white font-medium py-2 px-4 rounded cursor-pointer shadow-md transition duration-300"
              >
                Choose File
              </label>
              <input
                id="file-upload"
                type="file"
                className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
                onChange={(e) => {
                  const selectedFile = e.target.files[0];
                  setFile((prev) => ({ ...prev, file: selectedFile }));
                }}
              />
              {file.file && (
                <p className="mt-2 text-sm text-gray-600">
                  Selected File:{" "}
                  <span className="font-medium">{file.file.name}</span>
                </p>
              )}
            </div>
            <textarea
              className="w-[20vw] max-h-[30vh] px-4 py-2 text-gray-700 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 placeholder-gray-400"
              placeholder="Enter a description..."
              value={file.description}
              onChange={(e) => {
                setFile((prev) => ({ ...prev, description: e.target.value }));
                e.target.style.height = "auto"; // Reset height
                e.target.style.height = `${e.target.scrollHeight}px`; // Adjust height to fit content
              }}
            />

            <div className="relative w-[20vw]">
              <div className="flex items-center flex-wrap border border-gray-300 rounded-lg p-2 focus-within:ring focus-within:ring-blue-500">
                {file.tags.map((tag, index) => (
                  <div
                    key={index}
                    className="flex items-center bg-blue-600 text-white text-sm rounded-full px-3 py-1 mr-2 mb-2 shadow"
                  >
                    <span>{tag}</span>
                    <button
                      type="button"
                      className="ml-2 text-white font-bold hover:text-gray-300"
                      onClick={() => handleRemoveTag(index)}
                    >
                      &times;
                    </button>
                  </div>
                ))}

                <div className="relative flex-grow">
                  <input
                    type="text"
                    placeholder="Type to search or add tags"
                    className="w-full focus:outline-none text-gray-700 bg-transparent"
                    value={tagInput}
                    onChange={(e) => {
                      setTagInput(e.target.value);
                      handleFilterTags(e.target.value);
                    }}
                    onKeyDown={(e) => {
                      handleAddTag(e);
                      handleTagBackspace(e);
                    }}
                    onBlur={(e) => handleAddTagOnBlur(e)}
                  />
                  {filteredTags.length > 0 && (
                    <ul className="absolute z-10 bg-white border border-gray-300 rounded-md w-full mt-1 max-h-40 overflow-auto shadow-lg">
                      {filteredTags.map((tag, index) => (
                        <li
                          key={index}
                          className="px-3 py-2 cursor-pointer hover:bg-gray-100 text-sm text-gray-700"
                          onMouseDown={() => handleSelectTag(tag)}
                        >
                          {tag}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              </div>
            </div>
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
              onClick={handleSubmit}
            >
              Submit
            </button>
          </div>
        </div>

        {/* File List */}
        <div className="mt-5 w-[80vw]">
          <div className="flex items-center justify-between mb-4">
            <h2 className="text-2xl font-bold">File List</h2>
            <input
              type="text"
              placeholder="Search files..."
              className="border border-gray-300 rounded-lg px-3 py-2 text-sm w-64"
              value={searchQuery}
              onChange={handleSearchChange}
            />
          </div>
          {files.length === 0 ? (
            <p className="text-gray-600">No files found.</p>
          ) : (
            <div className="overflow-x-auto">
              <table className="min-w-full table-auto border-collapse border border-gray-200 shadow-lg">
                <thead className="bg-gray-100">
                  <tr>
                    <th className="border border-gray-300 px-4 py-2 text-left text-sm font-semibold text-gray-700">
                      File Name
                    </th>
                    <th className="border border-gray-300 px-4 py-2 text-left text-sm font-semibold text-gray-700">
                      Description
                    </th>
                    <th className="border border-gray-300 px-4 py-2 text-left text-sm font-semibold text-gray-700">
                      Tags
                    </th>
                    <th className="border border-gray-300 px-4 py-2 text-left text-sm font-semibold text-gray-700">
                      Requested times
                    </th>

                    <th className="border border-gray-300 px-4 py-2 text-center text-sm font-semibold text-gray-700">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {files.map((file, index) => (
                    <tr
                      key={file.file_id}
                      className={index % 2 === 0 ? "bg-white" : "bg-gray-50"}
                    >
                      <td className="border border-gray-300 px-4 py-2 text-sm text-gray-700">
                        {file.file_name}
                      </td>
                      <td className="border border-gray-300 px-4 py-2 text-sm text-gray-700">
                        {file.description}
                      </td>
                      <td className="border border-gray-300 px-4 py-2 text-sm text-gray-700">
                        {file.tags.join(", ")}
                      </td>
                      <td className="border border-gray-300 px-4 py-2 text-sm text-gray-700">
                        {file.count_requests}
                      </td>
                      <td className="border border-gray-300 px-4 py-2 text-center space-x-1 space-y-1">
                        <button
                          onClick={() => handleCopyURL(file)}
                          className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-1 px-3 rounded shadow"
                        >
                          Copy
                        </button>
                        <button
                          onClick={() => handleViewFile(file)}
                          className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-1 px-3 rounded shadow"
                        >
                          View
                        </button>
                        <button
                          onClick={() => handleDownloadFile(file)}
                          className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-1 px-3 rounded shadow"
                        >
                          Download
                        </button>
                        <button
                          onClick={() => openDeleteModal(file)}
                          className="bg-blue-500 hover:bg-red-600 text-white font-bold py-1 px-3 rounded shadow"
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>

        {/* Pagination */}
        <div>
          {pagination.previous && (
            <button onClick={() => handlePagination(pagination.previous)}>
              Previous
            </button>
          )}
          {pagination.next && (
            <button onClick={() => handlePagination(pagination.next)}>
              Next
            </button>
          )}
        </div>

        {/* Notification */}
        {notification.message && (
          <Notification
            title={notification.title}
            message={notification.message}
            color={notification.color}
            timeToDisappear={notification.timeToDisappear}
            onDisappear={handleResetNotification}
          />
        )}

        {/* Confirmation Modal */}
        {
          <ConfirmationModal
            isOpen={isModalOpen}
            onClose={closeDeleteModal}
            onConfirm={handleDeleteFile}
            title="Confirm Deletion"
            message={
              <span>
                Are you sure you want to delete this file?
                <br />
                <strong className="block text-red-500 text-center font-bold">
                  "{fileToDelete?.file_name}"
                </strong>
              </span>
            }
          />
        }
      </div>
    );
  }
}

export default FilesPage;
