import React, { useEffect, useState } from "react";

// Notification component
const Notification = ({
  title,
  message,
  color,
  timeToDisappear,
  onDisappear,
}) => {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(false);
      onDisappear(); // Call the callback after hiding the notification
    }, timeToDisappear);

    return () => {
      clearTimeout(timer);
    };
  }, [timeToDisappear, onDisappear]);

  if (!isVisible) return null;

  return (
    <div
      style={{
        position: "fixed",
        bottom: "10px",
        right: "10px",
        backgroundColor: color,
        padding: "10px 20px",
        borderRadius: "5px",
        boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
        zIndex: 1000,
        color: "white",
        fontSize: "16px",
      }}
    >
      <strong>{title}</strong>
      <p>{message}</p>
    </div>
  );
};

export default Notification;
