import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import ZendeskWidget from "../components/ZendeskWidget";
const SupportPage = () => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-white text-black">
      <div className="w-3/5 max-w-full text-center overflow-hidden">
        <h1 className="whitespace-normal">
          Click the right corner to contact Growatt Nederland
        </h1>
      </div>
      <div>
        <style>
          {`
          @keyframes flashThenDisappear {
            12.5%, 37.5%, 62.5%, 87.5% { opacity: 0; }
            0%, 25%, 50%, 75% { opacity: 1; }
            100% { opacity: 0; visibility: hidden; }
          }
        `}
        </style>
        <FontAwesomeIcon
          icon={faArrowRight}
          className="absolute text-5xl"
          style={{
            transform: "rotate(45deg)",
            right: "125px",
            bottom: "125px",
            animation: "flashThenDisappear 5s ease-in-out forwards",
          }}
        />
      </div>
      <ZendeskWidget />
    </div>
  );
};

export default SupportPage;
