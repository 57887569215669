// utils/loadPages.js
export default function loadPages() {
    const context = require.context('../pages', true, /\.js$/);
    return context.keys().map(key => {
      const pageModule = context(key);
      return {
        path: `/${key.replace(/\.\/|\.js/g, '')}`, // Example: /login
        element: pageModule.default,
        exact: true, // You can adjust this based on your routing needs
      };
    });
  }
