import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom"; // Import useNavigate if using React Router
import Notification from "../components/notification";
import backgroundImage from "../images/login_background.png";
import API, { setAuthToken } from "../utils/api";
import initializeUserData from "../utils/initUserData";

function LoginPage() {
  const next = useRef(null);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate(); // Use the useNavigate hook for navigation
  const dispatch = useDispatch();
  const [notification, setNotification] = useState({
    title: "",
    message: "",
    color: "",
    timeToDisappear: 3000,
  });

  const handleResetNotification = () => {
    setNotification({
      title: "",
      message: "",
      color: "",
      timeToDisappear: 3000,
    });
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    next.current = urlParams.get("next");
  }, []);

  const handleLogin = async (event) => {
    event.preventDefault();
    try {
      const response = await API.post("/login/", {
        email,
        password,
      });
      localStorage.setItem("token", response.data.token);
      localStorage.setItem("userName", response.data.userName);
      localStorage.setItem("userRoles", response.data.userRoles);
      initializeUserData(dispatch);
      setAuthToken(localStorage.getItem("token")); // Set the token in the axios headers
      if (next.current) {
        navigate(next.current); // Redirect to the next URL using React Router
      } else {
        navigate("/dashboard"); // Redirect to '/dashboard' using React Router
      }
    } catch (error) {
      console.error("Login error", error);
      // Handle errors here, such as displaying a notification
      setNotification({
        title: "Error",
        message: error.response.data.error,
        color: "red",
        timeToDisappear: 5000,
      });
    }
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const loginStyle = {
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "calc(10px + 2vmin)",
    height: "100%",
    width: "100vw",
    backgroundImage: `url(${backgroundImage})`,
  };

  return (
    <div style={loginStyle}>
      <div> Login </div>

      <form
        onSubmit={handleLogin}
        className="flex flex-col items-center space-y-2"
      >
        <div>
          <input
            value={email}
            onChange={handleEmailChange}
            type="text"
            className="sameLogin emailVal"
            placeholder="email"
          />
        </div>
        <div>
          <input
            value={password}
            onChange={handlePasswordChange}
            type="password"
            placeholder="Password"
          />
        </div>
        <div className="bg-[#009cff] text-white border-none rounded-md px-4 py-2 m-2 cursor-pointer text-base hover:bg-[#61dafb] hover:text-black">
          <button type="submit" className="round">
            Login
          </button>
        </div>
      </form>
      {notification.message && (
        <Notification
          title={notification.title}
          message={notification.message}
          color={notification.color}
          timeToDisappear={notification.timeToDisappear}
          onDisappear={handleResetNotification}
        />
      )}
    </div>
  );
}

export default LoginPage;
