// src/components/ZendeskWidget.js

import { useEffect } from "react";

const ZendeskWidget = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.id = "ze-snippet";
    script.async = true;
    script.src =
      script.src = `https://static.zdassets.com/ekr/snippet.js?key=${process.env.REACT_APP_ZENDESK_KEY}`;

    document.body.appendChild(script);

    // Handle when the script is loaded
    script.onload = () => {
      console.log("Zendesk script loaded successfully");

      // Wait for zE to be available
      const checkZendeskLoaded = setInterval(() => {
        if (window.zE) {
          clearInterval(checkZendeskLoaded);

          // Define the function to open Zendesk widget
          const openZendeskWidget = () => window.zE("messenger", "open");

          // Set a timeout to trigger the widget after 4 seconds
          setTimeout(openZendeskWidget, 4000);
        }
      }, 300); // Check every 100ms
    };

    // console.log(process.env.REACT_APP_ZENDESK_KEY);

    return () => {
      // Delay removal of script to ensure it initializes
      setTimeout(() => {
        const existingScript = document.getElementById("ze-snippet");
        if (existingScript) {
          document.body.removeChild(existingScript);
        }
      }, 5000); // Delay might need adjustment
    };
  }, []);

  return null; // This component doesn't render anything
};

export default ZendeskWidget;
