import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
  name: "user",
  initialState: {
    userName: null,
    userEmail: null,
    userRoles: null,
    userRole: null,
  },
  reducers: {
    setUser: (state, action) => {
      state.userName = action.payload.userName;
      state.userEmail = action.payload.userEmail;
      state.userRoles = action.payload.userRoles;
      state.userRole = action.payload.userRoles[-1];
    },
    changeRole: (state, action) => {
      state.userRole = action.payload;
    },
    logoutUser: (state) => {
      state.userName = null;
      state.userEmail = null;
      state.userRoles = null;
      state.userRole = null;
      localStorage.clear();
    },
  },
});

export const { setUser, logoutUser, changeRole } = userSlice.actions;

export default userSlice.reducer;
