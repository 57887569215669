import React, { useEffect, useState } from "react";
import Notification from "../../components/notification";
import API from "../../utils/api";

const deep_copy = (obj) => {
  return JSON.parse(JSON.stringify(obj));
};

const defaultFormData = {
  action: "Create",
  ticket: "",
  name: "",
  device_sn: "",
  datalogger_sn: "",
  update_datalogger: false,
  update_inverter: true,
  update_battery: false,
  inverter_firmware: "Latest",
  battery_firmware: "None",
  predefined_paths: "",
  status: "",
};

const resetFormData = {
  device_sn: "",
  datalogger_sn: "",
};

const actionMap = {
  Create: "Create a new task",
  Update: "Update to another firmware",
  Pause: "Pause a task",
  Resume: "Resume a task",
  Reset: "Restart a task from the beginning",
  Delete: "Delete a task",
};

function formSection(
  firmwareMap,
  formData,
  formErrors,
  handleChange,
  handleSubmit,
  handleClear,
) {
  const actionOptions = Object.keys(actionMap).map((key) => (
    <option key={key} value={key} title={actionMap[key]}>
      {key}
    </option>
  ));
  const InverterMap = firmwareMap.inverter;
  let InverterTypes = Object.keys(InverterMap);
  // sort inverter types alphabetically
  // add Latest if it does not exist and add it to the beginning
  InverterTypes.sort();
  if (formData.inverter_firmware !== "Latest") {
    InverterTypes.unshift("Latest");
  }

  const InverterOptions = InverterTypes.map((key) => (
    <option key={key} value={key}>
      {key}
    </option>
  ));

  const BatteryMap = firmwareMap.battery;
  let BatteryTypes = Object.keys(BatteryMap);
  // sort battery types alphabetically
  // add None if it does not exist and add it to the beginning
  BatteryTypes.sort();
  if (formData.battery_firmware !== "None") {
    BatteryTypes.unshift("None");
  }

  const BatteryOptions = BatteryTypes.map((key) => (
    <option key={key} value={key}>
      {key}
    </option>
  ));

  return (
    <div>
      <h1>Update Firmware Page</h1>
      <form
        className="flex flex-col gap-4 min-w-full items-center mt-10"
        onSubmit={handleSubmit}
      >
        <div className="flex flex-row space-x-4">
          <div className="flex flex-col">
            <label htmlFor="task">Action</label>
            <select
              name="action"
              id="action"
              value={formData.action}
              onChange={handleChange}
              className="border rounded border-gray-400"
            >
              {actionOptions}
            </select>
          </div>
          <div className="flex flex-col">
            <label htmlFor="ticket">Ticket</label>
            <input
              type="number"
              name="ticket"
              id="ticket"
              required
              value={formData.ticket}
              onChange={handleChange}
              className="border rounded border-gray-400"
            />
            {formErrors.ticket && (
              <span className="text-red-500 text-sm">{formErrors.ticket}</span>
            )}
          </div>
          <div className="flex flex-col">
            <label htmlFor="device_sn">Inverter SN</label>
            <input
              type="text"
              name="device_sn"
              id="device_sn"
              required
              minLength={10}
              maxLength={10}
              value={formData.device_sn}
              onChange={handleChange}
              className="border rounded border-gray-400"
            />
            {formErrors.device_sn && (
              <span className="text-red-500 text-sm">
                {formErrors.device_sn}
              </span>
            )}
          </div>
          <div className="flex flex-col">
            <label htmlFor="inverter_firmware">Inverter firmware</label>
            <select
              name="inverter_firmware"
              id="inverter_firmware"
              onChange={handleChange}
              className="border rounded border-gray-400"
            >
              <option value="">{formData.inverter_firmware}</option>
              {InverterOptions}
            </select>
          </div>
          <div className="flex flex-col">
            <label htmlFor="battery_firmware">Battery firmware</label>
            <select
              name="battery_firmware"
              id="battery_firmware"
              onChange={handleChange}
              className="border rounded border-gray-400"
            >
              <option value="">{formData.battery_firmware}</option>
              {BatteryOptions}
            </select>
          </div>
        </div>
        <div className="flex flex-col space-x-2">
          <div className="flex flex-row space-x-4 items-center justify-items-center">
            <div className="text-xl"> Update: </div>
            {/* <label htmlFor="update_datalogger">Datalogger</label>
          <input
            type="checkbox"
            name="update_datalogger"
            id="update_datalogger"
            checked={formData.update_datalogger}
            onChange={handleChange}
          /> */}
            <label htmlFor="update_inverter">Inverter</label>
            <input
              type="checkbox"
              name="update_inverter"
              id="update_inverter"
              checked={formData.update_inverter}
              onChange={handleChange}
            />
            <label htmlFor="update_battery">Battery</label>
            <input
              type="checkbox"
              name="update_battery"
              id="update_battery"
              disabled
              checked={formData.update_battery}
              onChange={handleChange}
            />
          </div>
          {formErrors.update && (
            <span className="text-red-500 text-sm">{formErrors.update}</span>
          )}
          <div className="flex flex-row space-x-4">
            <button
              type="submit"
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            >
              Submit
            </button>
            <button
              type="button"
              onClick={handleClear}
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            >
              Clear
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

// Validator function for submittedData
const validateFormData = (data) => {
  const errors = {};

  // Check device_sn length (must be exactly 10 characters)
  if (!data.device_sn || data.device_sn.length !== 10) {
    errors.device_sn = "Inverter SN must be exactly 10 characters.";
  }

  // Check datalogger_sn length (must be either 0 or 10 characters)
  if (data.datalogger_sn && data.datalogger_sn.length !== 10) {
    errors.datalogger_sn =
      "Datalogger SN must be either empty or exactly 10 characters.";
  }
  // Check if at least one device is selected for update
  if (!data.update_inverter && !data.update_battery) {
    errors.update = "Please select at least one device to update";
  }
  // Add other validations as needed
  // Example: Validate ticket number
  if (!data.ticket) {
    errors.ticket = "Ticket number is required.";
  }

  // Return an empty object if there are no errors
  return errors;
};

function SimpleUpdatePage() {
  const [notification, setNotification] = useState({
    title: "",
    message: "",
    color: "",
    timeToDisappear: 3000,
  });

  const handleResetNotification = () => {
    setNotification({
      title: "",
      message: "",
      color: "",
      timeToDisappear: 3000,
    });
  };
  const [firmwareMap, setFirmwareMap] = useState(null);

  const [formData, setFormData] = useState(deep_copy(defaultFormData));

  const [formErrors, setFormErrors] = useState({});

  const fetchFirmwareMap = () => {
    API.get("/update/firmware-map/")
      .then((response) => {
        setFirmwareMap(response.data);
      })
      .catch((error) => console.error("Error fetching data:", error));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    // check if battery firmware is changed
    if (name === "battery_firmware") {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
        update_battery: value !== "None",
      }));
    } else if (["device_sn", "datalogger_sn"].includes(name)) {
      // remove spaces new lines and tabs from serial numbers
      const new_value = value.replace(/\s/g, "");
      setFormData((prevData) => ({
        ...prevData,
        [name]: new_value,
      }));
    } else if (name.startsWith("update_")) {
      setFormData((prevData) => ({
        ...prevData,
        [name]: !prevData[name],
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }

    // console.log("Form data changed:", formData);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Perform validation
    const validationErrors = validateFormData(formData);
    if (Object.keys(validationErrors).length > 0) {
      setFormErrors(validationErrors);
      return; // Stop submission if there are validation errors
    }

    // remove unnecessary fields before submitting
    const {
      inverter_firmware,
      battery_firmware,
      update_datalogger,
      update_inverter,
      update_battery,
      ...submittedData
    } = deep_copy(formData);
    if (!update_datalogger && !update_inverter && !update_battery) {
      alert("Please select at least one device to update!");
      return;
    }
    console.log("inverter", inverter_firmware);
    console.log("battery", battery_firmware);
    if (update_inverter && update_battery) {
      submittedData["predefined_paths"] =
        inverter_firmware === "Latest"
          ? `+${battery_firmware}`
          : `${inverter_firmware}+${battery_firmware}`;
    } else if (update_battery) {
      submittedData["predefined_paths"] = `${battery_firmware}`;
    } else {
      submittedData["predefined_paths"] =
        inverter_firmware === "Latest" ? "" : `${inverter_firmware}`;
    }
    console.log("Form data submitted:", submittedData);
    API.post("/update/simple/action/", submittedData)
      .then((response) => {
        console.log("Form submitted successfully:", response.data);
        const message = response.data.message || "Task submitted successfully";
        setNotification({
          title: "Success",
          message: message,
          color: "green",
          timeToDisappear: 3000,
        });
        HandleSubmitClear();
      })
      .catch((error) => {
        console.error("Error submitting form:", error);
        const message =
          error.response?.data?.message || "Error submitting task";
        setNotification({
          title: "Error",
          message: message,
          color: "red",
          timeToDisappear: 5000,
        });
      });
  };

  const HandleSubmitClear = () => {
    const newFormData = { ...formData, ...resetFormData };
    setFormData(deep_copy(newFormData));
    setFormErrors({});
  };

  const handleClear = () => {
    setFormData(deep_copy(defaultFormData));
    setFormErrors({});
  };

  useEffect(() => {
    fetchFirmwareMap();
    const intervalId = setInterval(fetchFirmwareMap, 10000); // Fetch data every 10 seconds
    return () => clearInterval(intervalId); // Clear interval on component unmount
  }, []);

  if (!firmwareMap) {
    return (
      <div className="flex justify-center items-center h-screen text-lg">
        Loading...
      </div>
    );
  } else {
    return (
      <>
        {formSection(
          firmwareMap,
          formData,
          formErrors,
          handleChange,
          handleSubmit,
          handleClear,
        )}
        {notification.message && (
          <Notification
            title={notification.title}
            message={notification.message}
            color={notification.color}
            timeToDisappear={notification.timeToDisappear}
            onDisappear={handleResetNotification}
          />
        )}
      </>
    );
  }
}

export default SimpleUpdatePage;
