// In src/App.js

import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import "./App.css";
import AppContent from "./AppContent"; // Import the new component
import { setAuthToken } from "./utils/api";
import initializeUserData from "./utils/initUserData"; // Adjust path as necessary

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    // Initialize user data from localStorage on app start
    initializeUserData(dispatch);
  }, [dispatch]);

  setAuthToken(localStorage.getItem("token")); // Set the token from localStorage if it exists

  return (
    <Router>
      <AppContent />
    </Router>
  );
}

export default App;
