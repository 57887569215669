// utils/copyToClipboard.js
const copyToClipboard = async (text) => {
  if (navigator.clipboard && navigator.clipboard.writeText) {
    try {
      await navigator.clipboard.writeText(text);
      return true; // Success
    } catch (err) {
      console.error("Failed to copy text using clipboard API: ", err);
      return false; // Failure
    }
  } else {
    // Fallback for older browsers
    const textarea = document.createElement("textarea");
    textarea.value = text;
    document.body.appendChild(textarea);
    textarea.select();
    try {
      document.execCommand("copy");
      return true; // Success
    } catch (err) {
      console.error("Failed to copy text using execCommand: ", err);
      return false; // Failure
    } finally {
      document.body.removeChild(textarea);
    }
  }
};


export default copyToClipboard;
