// In src/AppContent.js

import React from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import AppHeader from "./components/head";
import SupportPage from "./pages/support";
import loadPages from "./utils/loadPages"; // Adjust the path as necessary
const noHeaderPages = ["/support", "/home", "/dashboard", "/"];
const AppContent = () => {
  const location = useLocation();
  const pages = loadPages();
  // default page is the home
  const defaultPage = pages.find((page) => page.path === "/home");
  return (
    <div className="App">
      {!noHeaderPages.includes(location.pathname) && <AppHeader />}
      <Routes>
        <Route path="/" element={<defaultPage.element />} />
        <Route path="/support" element={<SupportPage />} />
        {pages.map((page, index) => (
          <Route key={index} path={page.path} element={<page.element />} />
        ))}
        {/* Redirect to "/" if no routes match */}
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </div>
  );
};

export default AppContent;
