import React from "react";

function HomePage() {
  return (
    <div style={{ textAlign: "center", padding: "50px" }}>
      <h1>Welcome to Growatt Support</h1>
      <p>
        We are currently working hard to bring you the best experience possible.
      </p>
      <p>Stay tuned for updates and new features!</p>
    </div>
  );
}

export default HomePage;
